<template>
  <!-- 轮播图 -->
    <Banner :imgArr="state.bannerArr" v-if="state.bannerArr!=''" />
  <div class="section">
    <!-- <h2>{{state.curName}}</h2> -->
    <!-- <div class="time"><span style="margin-right:.5rem">2022-03-26</span><span>浏览量：7589</span></div> -->
    <Section :section="state.section" :topIsShow="state.topIsShow" v-if="state.section.leftNav != ''">  
      <!-- 物联网农业 -->
      <template v-slot:5 >
        <p class="animate__animated animate__fadeInUp wow">{{state.info[0].describe}}</p>
        <div class="imgBox animate__animated animate__fadeInUp wow">
            <img :src="item.images" v-for="(item,index) in state.info[0].imgs" :key="index" />
        </div>
      </template>
      <!-- 标准化种植 -->
      <template v-slot:6>
        <div  class="style1">
          <img :src="state.info[1].imgs[0].images"  class="animate__animated animate__bounceInRight wow"  />
          <p class="animate__animated animate__bounceInLeft wow">>{{state.info[1].describe}}</p>
        </div>
        <img :src="state.info[1].imgs[1].images" class=" animate__animated animate__fadeInUp wow" />
        <h6 class="animate__animated animate__fadeInUp wow">新建农场模块</h6>
        <img :src="state.info[1].imgs[2].images" class=" animate__animated animate__fadeInUp wow" />
        <div class="tit"><span class="animate__animated animate__fadeInUp wow">添加种植</span><span class="animate__animated animate__fadeInUp wow">种植管理</span></div>
        <img :src="state.info[1].imgs[3].images" class=" animate__animated animate__fadeInUp wow" />
      </template>
      <!-- 物联网监控 -->
      <template v-slot:7>
        <h5 class=" animate__animated animate__bounceInLeft wow">{{state.info[2].jump_url}}</h5>
        <img :src="item.images" v-for="(item,index) in state.info[2].imgs" :key="index"  class="animate__animated animate__fadeInUp wow"/>
        <!-- 2 -->
        <h5 class=" animate__animated animate__bounceInLeft wow">{{state.info[2].Internet[0].jump_url}}</h5>
        <div class="jkStyle2">
          <div class="jkStyle2Left animate__animated animate__bounceInRight wow">
            <p>{{state.info[2].Internet[0].describe}}</p>
            <img :src="state.info[2].Internet[0].imgs[0].images" :key="index"  class="animate__animated animate__fadeInUp wow" /> 
          </div>
          <img :src="state.info[2].Internet[0].imgs[1].images" :key="index"  class="animate__animated animate__bounceInRight wow"/> 
        </div>
        <!-- 3 -->
        <h5 class=" animate__animated animate__bounceInLeft wow">{{state.info[2].Internet[1].jump_url}}</h5>
        <div class="jkStyle2">
          <img :src="state.info[2].Internet[1].imgs[0].images" :key="index"  class="animate__animated animate__bounceInLeft wow"/> 
          <div class="jkStyle2Right animate__animated animate__bounceInRight wow" >
            <p>{{state.info[2].Internet[1].describe}}</p>
            <img :src="state.info[2].Internet[1].imgs[1].images" :key="index" class="animate__animated animate__fadeInUp wow" /> 
          </div>
        </div>
        <!-- 4 -->
        <h5 class=" animate__animated animate__bounceInLeft wow">{{state.info[2].Internet[2].jump_url}}</h5>
        <div class="jkStyle3">
          <img :src="state.info[2].Internet[2].imgs[0].images" :key="index"  class="animate__animated animate__bounceInLeft wow"/>
          <img :src="state.info[2].Internet[2].imgs[1].images" :key="index"  class="animate__animated animate__bounceInRight wow"/>  
        </div>
        <!-- 5 -->
        <h5 class="animate__animated animate__bounceInLeft wow">{{state.info[2].Internet[3].jump_url}}</h5>
          <p class="animate__animated animate__bounceInRight wow">{{state.info[2].Internet[3].describe}}</p>
          <div class="sfBox">
            <div class="sfLeft animate__animated animate__fadeInDown wow">
              <h6>数据</h6>
              <p>{{state.info[2].Internet[3].describe1}}</p>
            </div>
            <div class="sfCenter animate__animated animate__fadeInDown wow"><img :src="state.info[2].Internet[3].imgs[0].images" :key="index" />  </div>
            <div class="sfRight animate__animated animate__fadeInDown wow">
              <h6>优势</h6>
              <p>{{state.info[2].Internet[3].describe2}}</p>
            </div>
          </div>
          <!-- 6 -->
        <h5 class=" animate__animated animate__bounceInLeft wow">{{state.info[2].Internet[4].jump_url}}</h5>
        <ul class="fzsbList animate__animated animate__fadeInUp wow">
          <li>
            <img :src="state.info[2].Internet[4].imgs[0].images" :key="index" />
            <span>电动卷式喷灌机</span>
          </li>
          <li>
            <img :src="state.info[2].Internet[4].imgs[1].images" :key="index" />
            <span>植保无人机</span>
          </li>
          <li>
            <img :src="state.info[2].Internet[4].imgs[2].images" :key="index" />
            <span>视频检测</span>
          </li>
          <li>
            <img :src="state.info[2].Internet[4].imgs[3].images" :key="index" />
            <span>农具箱</span>
          </li>
        </ul>
      </template>
      <!-- 智慧病虫害识别 -->
      <template v-slot:13>
          <p class="animate__animated animate__fadeInUp wow">{{state.info[3].describe}}</p>
          <img :src="state.info[3].imgs[0].images" class="animate__animated  animate__bounceInUp  wow" />
          <img :src="state.info[3].imgs[1].images" class="animate__animated animate__fadeInUp wow" />
      </template>
      <template v-slot:14>
        <p class="animate__animated animate__fadeInUp wow">{{state.info[4].describe}}</p>
        <img :src="state.info[4].imgs[0].images" class="animate__animated  animate__bounceInUp  wow" />
      </template>
      <template v-slot:15>
        <p class="animate__animated animate__fadeInUp wow">{{state.info[5].describe}}</p>
        <img :src="state.info[5].imgs[0].images" class="animate__animated  animate__bounceInUp  wow" />
      </template>
    </Section>
  </div>
  <!-- 返回顶部 -->
  <img src="@/assets/images/top.png" class="top" v-if="state.topIsShow" @click="backTop" />
  <img src="@/assets/images/logoBg.png" class="logoSize logoBgLeft logoBg1" />
  <img src="@/assets/images/logoBg.png" class="logoSize logoBgRight logoBg2" />
  <img src="@/assets/images/logoBg.png" class="logoSize logoBgLeft logoBg3" />
  <img src="@/assets/images/logoBg.png" class="logoSize logoBgRight logoBg4" />
  <img src="@/assets/images/logoBg.png" class="logoSize logoBgLeft logoBg5" />
  <img src="@/assets/images/logoBg.png" class="logoSize logoBgRight logoBg6" />
</template>

<script>
import { useRoute } from 'vue-router'
import {onMounted,watchEffect,reactive} from "vue";
import {WOW} from 'wowjs' 
import 'animate.css'
import Banner from "@/components/banner1.vue"
// import { getYewDetail} from "../../api/request"
import Section from "./components/sectionNav.vue"
import { getWitlist,getbannerData} from "../../api/request"
export default {
  name: "Business",
  components:{
    Banner,
    Section
  },
  setup() {
    const route = useRoute()
    onMounted(()=>{
        var wow = new WOW({
        boxClass: 'wow',    //需要执行动画元素的Class
        animateClass: 'animated',    //animation.css动画的Class
        offset: 100,    //距离可视区域多少开始执行动画
        mobile: true,    //是否在移动设备执行动画
        live: true    //异步加载的内容是否有效
        })
        wow.init()
        getBanner()
        window.scrollTo(0,0);
      })
        
    let state = reactive({
      bannerArr:[],
      curId:0,
      curName:"",
      info:"",
      section:{
        title:"",
        leftNav:[
        ],
        isShow:false
      },
      scrollHeight:0,
      topIsShow:false 
    })
    watchEffect(()=>{
        state.curId = route.query.id
        getData(state.curId)
        // window.scrollTo(0,0);
         window.addEventListener('scroll',scrollY,true)
        // scrollY()
        
    })
    function getBanner(){
      getbannerData({id:37}).then((res)=>{
        // console.log(res)
        if(res.code == 200){
          state.bannerArr = res.data
        }
      })
    }
    function getData(id){
      getWitlist({id:id}).then((res)=>{
        // console.log(id)
        console.log(res)
        state.section.leftNav = res.data.title
        state.section.title = res.data.top_t.name
        state.info =  res.data
        state.section.isShow = true
        // console.log(state.info)
      })
    }
    // 滚动条距离顶部距离
    function scrollY(){
      state.scrollHeight = window.pageYOffset
      if(state.scrollHeight > 500){
        state.topIsShow = true
      }else{
        state.topIsShow = false
      }
    }
    function backTop(){
        // window.scrollTo(0,0);
      let timer = setInterval(() => {
        let ispeed = Math.floor(-state.scrollHeight / 5)
        document.documentElement.scrollTop = document.body.scrollTop = state.scrollHeight + ispeed
        if (state.scrollHeight === 0) {
          clearInterval(timer)
        }
      }, 16)
    }
    return {
      state,
      getData,
      getBanner,
      scrollY,
      backTop
    };
  },
};
</script>

<style lang="scss" scoped>
  .section{
    padding-bottom: 1rem;
    p{
      font-size: .16rem;
      color: #555;
      line-height: .3rem;
      text-indent: .3rem;
    }
    img{
      margin: .5rem auto ;
      max-width: 100%;
      display: block;
    }
    h5{
      font-size: .18rem;
      color: #555;
      line-height: 1rem;
      text-align: center;
    }
    h6{
        margin-top: -.2rem;
        font-size: .18rem;
        color: #555;
        text-align: center;
        font-weight: normal;
      }
    .imgBox{
      display: flex;
      margin: .2rem 0 0;
      img{
        margin: .1rem .05rem;
      }
    }
    .style1{
      display: flex;
      p{
        flex: 1;
        margin: 1rem .2rem 0 0;
      }
      img{
        width: 1.69rem;
        height: 3.47rem;
        margin-right: .5rem;
      } 
    }
    .tit{
        display: flex;
        width: 100%;
        span{
          display: block;
          flex: 1;
          text-align: center;
          margin-top: -.2rem;
          font-size: .18rem;
          color: #555;
          text-align: center;
          font-weight: normal;
        }
      }
    .jkStyle2{
      display: flex;
      .jkStyle2Left{
        margin-right: .5rem;
        img{
          margin-top: .2rem;
          animation-delay: .5s;
        }
      }
      .jkStyle2Right{
        margin-left: .5rem;
        p{
          line-height: .35rem;
        }
        img{
          margin-top: .2rem;
          animation-delay: .5s;
        }
      }
      img{
        margin-top: 0;
      }
    }
    .jkStyle3{
      display: flex;
      img:nth-child(1){
        width:5.35rem;
        height:3.44rem;
        margin-right: .5rem;
      }
    }
    .sfBox{
      display: flex;
      margin-top: .5rem;
      h6{
        color: #fff;
        height: .26rem;
        line-height: .26rem;
        margin: 0 .2rem .1rem;
        font-size: .16rem;
      }
      .sfLeft{
        width: 1.87rem;
        margin-right: .7rem;
        h6{
          background: #D1C02C;
        }
      }
      .sfRight{
        width: 1.91rem;
        margin-left: .7rem;
        animation-delay: .4s;
        h6{
          background: #43996E;
        }
      }
      .sfCenter{
        animation-delay: .2s;
        img{
          margin-top: 0;
        }
      }
    }
    .fzsbList{
      display: flex;
      li{
        margin: 0 .2rem;
        img{
          margin: 0 0 .05rem;
        }
        span{
          font-size: .16rem;
          color: #555;
          line-height: .3rem;
          display: block;
          text-align: center;
        }
      }
    }
  }
  .top{
    position: fixed;
    right: 0;
    bottom: 1rem;
    width: .5rem;
    height: .5rem;
    cursor: pointer;
    z-index: 9999;
  }
  .logoSize{
    width: 1.065rem;
    height: 1.81rem;
  }
  .logoBgLeft{
    position: absolute;
    left: 0;
  }
  .logoBgRight{
    transform:rotate(180deg);
    position: absolute;
    right: 0;
  }
  .logoBg1{
    top: 10rem;
  }
  .logoBg2{
    top: 20rem;
  }
  .logoBg3{
    top: 30rem;
  }
  .logoBg4{
    top: 40rem;
  }
  .logoBg5{
    top: 50rem;
  }
</style>